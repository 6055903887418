import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import * as _ from 'lodash';

@Injectable()
export class ProductService {

  constructor(private httpClient: HttpClient) { }

  getAllProducts(pageNo, recordPerPage, order?, sort?): Observable<any> {
    const params = {
      page: pageNo,
      record: recordPerPage,
      order: order || 1,
      sort: sort || 'size',
    };
    return this.httpClient.get('/product', {params: params, observe: 'response'}).map(res => {
      _.each(res.body, (product) => {
        product.images[0] = product.images[0].replace('server', '');
      });
      return res.body;
    });
  }

  getProductsByCategory(category): Observable<any> {
    const params = {
      category: category
    };
    return this.httpClient.get('/product', {params: params, observe: 'response'}).map(res => {
      _.each(res.body, (product) => {
        product.images[0] = product.images[0].replace('server', '');
      });
      return res.body;
    });
  }

  getSingleProduct(id) {
    return this.httpClient.get(`/product/${id}`, {observe: 'response'}).map(res => {
      _.each(res.body['images'], (image, index) => {
        res.body['images'][index] = image.replace('server', '');
      });
      return res.body;
    });
  }

  addProduct(product): Observable<any> {
    return this.httpClient.post('/product', product, {observe: 'response'}).map((res: any) => {
      _.each(res.body.images, (image) => {
        image = image.replace('server', '');
      });
      return res.body;
    });
  }

  deleteProduct(id) {
    return this.httpClient.delete(`/product/${id}`, {observe: 'response'}).map((res: any) => {
      return res.body;
    });
  }

  updateProduct(product, id) {
    return this.httpClient.put(`/product/${id}`, product, {observe: 'response'}).map((res: any) => {
      _.each(res.images, (image) => {
        image = image.replace('server', '');
      });
      return res.body;
    });
  }
}
